import { IMapDistance } from '@/types/interface';
import { apiClient } from './apiService';

export const mapService = {
  getMapDistance(data: IMapDistance) {
    return apiClient.request({
      method: 'POST',
      url: '/map/distance',
      data,
    });
  },
};
